<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <div class="container-fluid section-head">
      <div class="container-standar container-res-1440">
        <h1
          class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block"
        >{{ dataContent.nama }}</h1>
      </div>
    </div>
    <div class="cotainer-standar container-res-1440 text-center">
      <div class="container-standar mt-10 mb-10 pad-20">
        <div class="row">
          <div class="col-lg-8 pad-20 berita-content card shadow">
            <div class="img_haji">
                 <slick  ref="slick"   class="slickHome slickDetailUp" :options="slickOptions">
                    <div class="slick-content-detail" :key="index" v-for="(items, index) in slideData" >
                      <!-- <img src="@/assets/images/background/slide-1.jpg" alt /> -->
                      <img  :src="items.file" :alt="items.slug" />
                    </div>
                    
                  </slick>
              <div class="overflow-img text-left hide-mobile">
                <div class="float-left w-50">
                  <span
                    class="text-white font-24 d-inline-block w-100 font-weight-6"
                  >{{ dataContent.nama }}</span>
                  <span
                    class="text-white font-24 d-inline-block w-100 font-weight-6"
                  >IDR. {{dataContent.harga | numeralFormat('0,0[.]00')}}</span>
                </div>
                <div class="float-right w-50 text-right">
                  <!-- <span
                    class="text-white font-16 d-inline-block w-100 font-weight-6 mb-5"
                  >Tanggal Keberangkatan</span>
                  <span
                    class="text-white font-24 d-inline-block w-100 font-weight-6"
                  >12 Oktober 2020</span>-->
                </div>
              </div>
            </div>
            <div class="description d-inline-block mt-7 text-left w-100">
              <span class="font-20 font-weight-7 text-primary">Informasi Umum :</span>
              <div class="mt-2 ln-2" v-html="dataContent.display_deskripsi"></div>
            </div>
            <div class="inline-block w-100 mt-8" v-show="openFirst">
              <b-tabs fill>
                <b-tab title="Detail" class="text-left" active>
                  <div class="clear" v-html="dataContent.deskripsi"></div>
                </b-tab>
                <b-tab title="Itinerary" class="text-left">
                  <div class="post-content pad-20 mt-10">
                    <div class="post-paragraph clear" v-html="dataContent.itinerary"></div>
                  </div>
                </b-tab>
                <!-- <b-tab title="Media" class="text-left" >
                        <b-card-text>Media</b-card-text>
                </b-tab>-->
              </b-tabs>
            </div>
          </div>
          <div class="col-lg-4 res-no-pad">
            <div v-show="!LoginCheck">
              <div class="card text-left shadow overflow-hidden">
                <span
                  class="d-block text-center w-100 font-18 text-white pad-5 font-weight-6 bg-danger"
                  v-if="$store.state.userdata == null"
                >Harap Login Terlebih Dahulu</span>
                <span
                  class="text-warning border-bottom-2 bc-warning pt-2 pb-2 font-20 text-center d-block w-100 font-weight-6"
                >Pendaftaran</span>
                <div
                  class="width-100 pl-4 pr-4 pt-3 pb-3 d-inline-block border-bottom-1 bc-warning"
                >
                  <span
                    class="text-primary d-block width-100 font-16 mb-20 font-weight-6"
                  >{{ dataContent.nama }}</span>
                  <!-- <span class="float-left width-35 text-dark font-14 mb-10 font-weight-6">Keberangkatan</span>
                <span
                  class="float-left width-65  text-right text-warning font-14 mb-10 font-weight-6"
                  >Selasa 20 Oktober 2010</span>-->

                  <span class="float-left width-35 text-dark font-14 mb-10 font-weight-6">Price/Pax</span>
                  <span
                    class="float-left width-65 text-right text-danger font-16 mb-10 font-weight-6"
                  >IDR. {{ dataContent.harga | numeralFormat('0,0[.]00') }}</span>
                </div>
                <div
                  class="width-100 pl-4 pr-4 pt-1 border-bottom-1 bc-warning pb-3 d-inline-block"
                >
                  <div class="form-group mt-1">
                    <label for="name" class="text-primary font-12 font-weight-6">Nama Lengkap</label>
                    <input
                      type="text"
                      placeholder="Nama Lengkap"
                      v-model="name_User"
                      name="Name"
                      v-validate="'required'"
                      class="input-same-select pos-relative"
                    />
                    <span class="eror text-danger font-10">{{errors.first('Name')}}</span>
                  </div>

                  <div class="form-group">
                    <label for="name" class="text-primary font-12 font-weight-6">Nomor Hp/Wa</label>
                    <input
                      type="number"
                      placeholder="Nomor Hp/Wa"
                      v-model="phone"
                      name="phone_number"
                      v-validate="'required'"
                      class="input-same-select pos-relative"
                    />
                    <span class="eror text-danger font-10">{{errors.first('phone_number')}}</span>
                  </div>

                  <!-- <div class="form-group">
                    <label
                      for="destination"
                      class="text-primary font-12 font-weight-6"
                    >Pilih Setoran Awal</label>
                    <v-select
                      class="caret-icon"
                      :options="sort"
                      label="text"
                      v-model="Visa_pick"
                      :value="sort.text"
                      v-validate="'required'"
                      name="LayananVisa"
                      placeholder="Pilih Setoran Awal"
                    ></v-select>
                    <span class="eror text-danger font-10">{{errors.first('LayananVisa')}}</span>
                  </div>-->
                  <!-- <div class="form-group">
                    <label
                      for="anyDes"
                      class="text-primary font-12 font-weight-6"
                    >Tanggal Keberangkatan</label>
                    <div class="icon-date">
                      <flat-pickr
                        v-model="tourDate"
                        :config="config"
                        class="input-same-select"
                        placeholder="Select date"
                        name="date_tour"
                        v-validate="'required'"
                      ></flat-pickr>
                      <span class="eror text-danger font-10">{{errors.first('date_tour')}}</span>
                    </div>
                  </div> -->

                  <div class="group-field">
                    <label for="anyDes" class="text-primary font-12 font-weight-6">Jumlah Pendaftar</label>
                    <div class="input-group">
                      <input
                        type="number"
                        name="JumlahVisa"
                        class="form-control"
                        v-model="paxTour"
                        placeholder="Input Jumlah Pax"
                        v-validate="'min_value:1'"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">Pax</span>
                      </div>
                    </div>
                    <span class="eror text-danger font-10">{{errors.first('JumlahVisa')}}</span>
                  </div>
                </div>
                <!-- <div class="width-100 pl-4 pr-4 pt-3 border-bottom-1 bc-warning pb-3 d-inline-block">
                <span class="float-left width-35 text-dark font-14 mb-10 font-weight-6">
                  Room 1
                  <br />
                  <span class="font-weight-5">2 Adult Twin Sharing</span>
                </span>
                <span
                  class="float-left width-65 text-right text-danger font-16 mb-10 font-weight-6"
                >IDR. 1.524.000</span>
                </div>-->

                <div
                  class="width-100 pl-4 pr-4 pt-3 border-bottom-1 bc-warning pb-3 d-inline-block"
                >
                  <span
                    class="float-left width-35 text-primary font-18 mb-10 font-weight-6"
                  >Total DP</span>
                  <span
                    class="float-left width-65 text-right text-danger font-18 mb-10 font-weight-6"
                  >IDR. {{ paxCalculate(dataContent.dp_harga) | numeralFormat('0,0[.]00') }}</span>
                  <span
                    class="float-left width-35 text-primary font-18 mb-10 font-weight-6"
                  >Total Harga</span>
                  <span
                    class="float-left width-65 text-right text-danger font-18 mb-10 font-weight-6"
                  >IDR. {{ paxCalculate(dataContent.harga) | numeralFormat('0,0[.]00') }}</span>
                </div>
                <div class="form-group pt-4 pb-4 pad-10 d-inline-block">
                  <span
                    @click.prevent="Validation()"
                    class="d-block w-70 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white"
                  >Selanjutnya</span>
                </div>
              </div>
            </div>
            <div v-show="LoginCheck">
              <div class="card shadow text-left pad-10">
                <span
                  class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                >Review Pemesanan</span>
                <div class="form-group d-inline-block mt-4">
                  <span class="float-left">Nama Pemesan</span>
                  <span class="float-right font-weight-6 text-primary">{{ name_User }}</span>
                </div>
                <div class="form-group d-inline-block">
                  <span class="float-left">No Telp/HP</span>
                  <span class="float-right font-weight-6 text-primary">{{ phone }}</span>
                </div>
                <div class="form-group d-inline-block mt-4 mb-1 bg-warning-light pad-10">
                  <span class="float-left font-weight-6 text-warning font-16">Detail Pemesanan</span>
                </div>
                <div class="form-group d-inline-block pl-2 pr-2 mt-4">
                  <span class="float-left">Nama Tour</span>
                  <span class="float-right font-weight-6 text-primary">{{ dataContent.nama }}</span>
                </div>
                <div class="form-group d-inline-block pl-2 pr-2 mt-4">
                  <span class="float-left">Harga/pax</span>
                  <span
                    class="float-right font-weight-6 text-primary"
                  >IDR. {{ dataContent.harga | numeralFormat('0,0[.]00') }}</span>
                </div>

                <!-- <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Tanggal Keberangkatan</span>
                  <span class="float-right font-weight-6 text-primary">{{ tourDate }}</span>
                </div> -->

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Jumlah Pax</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>{{ paxTour }}</span> PAX
                  </span>
                </div>

                <div class="form-group d-inline-block pl-2 pr-2">
                  <span class="float-left">Total DP</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ paxCalculate(dataContent.dp_harga) | numeralFormat('0,0[.]00') }}
                  </span>
                </div>

                <div class="form-group d-inline-block bg-danger-light pt-3 pb-3 pl-2 pr-2">
                  <span class="float-left font-weight-6">Total Harga</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ paxCalculate(dataContent.harga) | numeralFormat('0,0[.]00') }}
                  </span>
                </div>

                <div class="form-group d-inline-block pt-3 pb-3 pl-2 pr-2">
                  <span
                    class="d-block w-100 mb-6 cursor-pointer bg-primary text-white text-center pad-7 border-radius-5"
                    @click.prevent="backTo()"
                  >Kembali</span>

                  <span
                    class="d-block w-100 bg-warning cursor-pointer text-white text-center pad-7 border-radius-5"
                    @click.prevent="OpenPayment()"
                  >Bayar </span>
                </div>
              </div>
            </div>

            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
        <b-modal id="modal-Success"   hide-footer no-close-on-backdrop centered title>
      <div class="col-md-12 pb-6 text-center">
        <img width="150px" class="margin-auto pb-10 pt-4" src="@/assets/images/icon/check.svg" alt />
        <span class="text-primary font-weight-6 font-18">Pesanan Anda Sudah di Proses</span>
        <br /> 
           <span class="text-grey font-weight-5 font-16 d-block mb-20">Silahkan Pilih Pembayaran</span>
         <span @click.prevent="PostData(2)"
          class="d-block width-50 bg-primary   pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto mb-15  cursor-pointer"
        >Bayar DP</span>
        <span @click.prevent="PostData(3)"
          class="d-block width-50 bg-warning pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto mb-15   cursor-pointer"
        >Bayar Lunas</span>
      </div>
    </b-modal>
      <b-modal id="modal-Payment" scrollable    hide-footer no-close-on-backdrop centered  >
    <template v-slot:modal-title>
     <span class="d-block font-18 text-primary font-weight-7">Pilih Metode Pembayaran</span>
    </template>
        <payment :dataBinding='trasaction_inv'></payment>
 
    </b-modal>
  </div>
</template>
<script>
// import SocialSharing from "vue-social-sharing";

// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
// import * as moment from "moment";
import stripHtml from "string-strip-html";
import Slick from "vue-slick";
import payment from "@/views/components/payment.vue";
export default {
  components: {
    // SocialSharing
    Slick,
    payment,
    // flatPickr
  },
  data() {
    return {
      name_User: null,
      LoginCheck: false,
      // tourDate: null,
      openFirst: true,
      paxTour: 1,
       slideData:[],
       trasaction_inv:null,
      phone: null,
      email: null,
      dataContent: {},
      date: new Date(),
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      },
      slickOptions: {
        slidesToShow: 1,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false, 
        arrows: false, 
      },
    };
  },
  watch: {
    slideData() {
      let currIndex = this.$refs.slick.currentSlide() 
      this.$refs.slick.destroy()
      this.$nextTick(() => {
        this.$refs.slick.create()
        this.$refs.slick.goTo(currIndex, true)
      })
      
    }
  },
  mounted() {
    this.getData();
    this.$store.commit("SET_BG", false);

    if (window.screen.width <= 800) {
      this.responsive = false;
      // console.log('mobile');
      this.respon = true;
    } else {
      this.responsive = true;
      this.respon = false;
      //  console.log('desktop');
    }
  },
  methods: {
    OpenPayment(){
        this.$bvModal.show("modal-Success");
    },
    paxCalculate(neVal) {
      if (this.paxTour > 0) {
        return neVal * this.paxTour;
      } else {
        return neVal;
      }
    },
    getData() {
      // console.log(this.$route.params.slug)
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tour");
      param.append("type", "haji");
      param.append("call", "list_product");
      param.append("product_id", "");
      param.append("slug", this.$route.params.slug);
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain"
          })
        })
        .then(response => {
          let res = response.data.data[0]; 
          this.dataContent = res;
          this.slideData = res.images
          // console.log(res);
          // console.log(res)
          // this.destination = res.result
        })
        .catch(error => {
          error;
        });
    },
    Validation() {
   
          // this.Visa_pick = this.Visa_pick.text;
          if (this.$store.state.userdata !== null) {
         
            this.validator();
             this.LoginCheck = true;
          } else {
            this.$bvModal.show("modal-login");
          } 
    },
    validator(){
        this.$validator.validateAll().then(valid => {
        if (valid) { 
          this.LoginCheck = true;
        }
         this.LoginCheck = true;
        });
    },
    contentDesc(NewVal) {
      return stripHtml(NewVal);
    },
     PostData(newVal){
        let param = new FormData();
     
           param.append("api_key", "2222");
            param.append("format", "");
            param.append("ws", "tour"); 
            param.append("call", "book_product");
             param.append("partner_id", this.$store.state.userdata.partner_id);  
            param.append("product_id", this.$route.params.id);  
            param.append("name", this.name_User);
             param.append("phone", this.phone);
              param.append("pax", this.paxTour);
              //  param.append("date_depart", moment(this.tourDate).format("YYYY-MM-DD"))
              if(newVal == 2){
                  param.append("amount",  this.dataContent.dp_harga);
              }else{
                param.append("amount",  this.dataContent.harga);
              }
             
     
          this.axios
            .post("https://apitiket.realtravel.co.id/ws", param, {
                headers: new Headers({
                  accept: "application/json",
                  "Content-Type": "multipart/form-data, text/plain"
                })
              })
            .then(response => {
              let res = response
                 this.$bvModal.hide("modal-Success");
              if(res.status == 200){
              // this.$bvModal.hide("modal-Success");
                this.trasaction_inv = res.data.data.transaction_detail.invoice_number 
                setTimeout(() => {
                     this.$bvModal.show("modal-Payment");
                }, 500);
              }
            
              // console.log(res);
          });
     },
    checkresponsive() {
      this.openFirst = false;
      this.responsive = true;
    },
    backresponsive() {
      this.openFirst = true;
      this.responsive = false;
    },
    backTo() {
      this.LoginCheck = false;
    }
  },
  name: "visa"
};
</script>